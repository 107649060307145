(function ($) {
    //jquery modal


    $(".carousel .slide__content").slick({
        arrows: false,
        swipeToSlide: true,
        swipe: true,  // ipad上，不允许拖动，同上
        dots: true,
        speed: 300,
        slidesToShow: 1,
        infinite: true,
        autoplay: true,
        //  autoplaySpeed:3000, //default 3000
        // fade: true,  //淡入
        // cssEase: 'linear'
    });

    $(".open").click(function (e) {
        e.preventDefault();
        //$('html').css("overflow", "hidden");
        console.log($(".menu-container").height(), $(window).height())
        $(".menu-container").show();
        $(".main-content").hide();
        //disableScroll();
    })
    $(".close").click(function (e) {
        e.preventDefault();
        $(".menu-container").hide();
        $(".main-content").show();
        //enableScroll();
        // $('html').css("overflow", "auto");
    })
    $(".menu-container").click(function (e) {
        // e.preventDefault();
        $(".menu-container").hide();
        $(".main-content").show();
     //   enableScroll();
    })


    //sub nav
    $(".second-navigation a").click(function (e) {
        var p = $(this).parent();
        var parents = $(this).parents(".second-navigation");
        parents.find(".item").removeClass("current")
        // $(this).parent().siblings().removeClass("current")
        p.addClass("current");
        var targetId = $(this).data("target");

        //第三级
        //Product
        $(".third-navigation").removeClass("active")
        $(".third-navigation[data-id='" + targetId + "']").addClass("active")

        $(".content-tabs .tab").removeClass("active");
        $(".content-tabs .tab[data-id='" + targetId + "']").addClass("active");
    });
    $(".third-navigation a").click(function (e) {
        var p = $(this).parent();
        var parents = $(this).parents(".third-navigation");
        parents.find(".third-item").removeClass("current");
        //$(this).parent().siblings().removeClass("current")
        p.addClass("current");
        var targetId = $(this).data("target");

        $(".third-tab").removeClass("active")
        $(".third-tab[data-id='" + targetId + "']").addClass("active")
    });

    $(".btn-share").click(function (e) {
        e.preventDefault();
        var link = $(this).attr("href");
        var host = window.location.protocol + "//" + window.location.host;

        // navigator.clipboard.writeText(host + link);
        copyToClipboard(host + link)
        $(".dialog-container").show();
        $(".dialog-container").css("display", "flex")
        $(".dialog-container").css({
            "display": "flex",
            "top": $(document).scrollTop(),
        })
        //$("html").css({overflow: 'hidden'})
        $(".dialog-container").html('<div class="dialog">\n' +
            '            <div class="icon"><img src="/dist/images/icon.svg"></div>\n' +
            '            <div class="msg">\n' +
            '                <p>The file download address has been copied successfully!</p>\n' +
            '            </div>\n' +
            '        </div>');
        disableScroll();

    })
    $(".dialog-container").click(function (e) {
        $(".dialog-container").hide();
        enableScroll();
    });

    //显示feedback
    var maxWidth = $(".main-content").width();
    console.log(maxWidth)

    $("#open-msg").on('click', function (e) {
        e.preventDefault();

        var step = $("#feedback-step-1").css("width", maxWidth)
        console.log(step.modal({
            escapeClose: false,
            clickClose: false,
            showClose: false
        }));

    });
    var formPanel;
    $("#next-show-form").on("click", function (e) {
        e.preventDefault();
        var href = $(this).attr("href");
        formPanel = $(href).css("width", maxWidth)
        $(href).modal({
            escapeClose: false,
            clickClose: false,
            showSpinner: true,
            //showClose: false
        })
    })
    $("#feedback-message").on("keyup", function(){
        var v = $.trim($(this).val());

        if(v != "") {
            $("#btn-submit-feedback").removeClass("btn-disabled")
        }else {
            $("#btn-submit-feedback").addClass("btn-disabled")
        }
    })
    $("#btn-submit-feedback").on("click", function (e){
        e.preventDefault();
        var href = $(this).attr("href");
        var step = $(href).css("width", maxWidth)
        if($(this).hasClass("btn-disabled")) {
            return false;
        }
        $.modal.getCurrent().showSpinner()
        $.ajax({
            url:"/feedback/message",
            method: "POST",
            data: {
                email: $("#feedback-email").val(),
                message: $("#feedback-message").val(),
            },
            dataType: "json"
        }).done(function (data){
            if(data && data.success) {
                $.modal.getCurrent().hideSpinner()
                $(href).modal({
                    escapeClose: true, //esc键退出
                    clickClose: true,
                    showClose: false
                })
            }

        }).fail(function ( jqXHR, textStatus){
            alert("fail " + textStatus)
            $.modal.getCurrent().hideSpinner()
        })
        return;
        /*setTimeout(function(){
            $.modal.close();
        }, 2000)*/
    })
})(jQuery)

function copyToClipboard(string) {
    let textarea;
    let result;

    try {
        textarea = document.createElement('textarea');
        textarea.setAttribute('readonly', true);
        textarea.setAttribute('contenteditable', true);
        textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
        textarea.value = string;

        document.body.appendChild(textarea);

        textarea.focus();
        textarea.select();

        const range = document.createRange();
        range.selectNodeContents(textarea);

        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        textarea.setSelectionRange(0, textarea.value.length);
        result = document.execCommand('copy');
    } catch (err) {
        console.error(err);
        result = null;
    } finally {
        document.body.removeChild(textarea);
    }

    // manual copy fallback using prompt
    if (!result) {
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
        result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
        if (!result) {
            return false;
        }
    }
    return true;
}

