(function ($){
    $(document).ready(function (){
        $("textarea").on("keyup", function (e){
            var element = e.target;
            element.style.height = "1px";

            if (element.scrollHeight <= 260) {
                element.style.height = element.scrollHeight+"px";
                return
            }
            element.style.height = (25+element.scrollHeight)+"px";
        })
    })
})(jQuery)